import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import ExternalLink from '../../../components/ExternalLink';
import LeadParagraph from '../../../components/LeadParagraph';
import Image from '../../../components/Image';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "logo",
      "style": {
        "position": "relative"
      }
    }}>{`Logo`}<a parentName="h1" {...{
        "href": "#logo",
        "aria-label": "logo permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  The City of Helsinki logo is used in digital services for identifying the site as an official City of Helsinki service.
    </LeadParagraph>
    <p>{`The City of Helsinki logo consists of the text `}<em parentName="p">{`Helsinki`}</em>{` framed by the shape derived from the city crest. The logo must be used in accordance with the `}<ExternalLink href="https://brand.hel.fi/en/logo" mdxType="ExternalLink">{`Visual Identity Guidelines - Logo`}</ExternalLink>{`. See the `}<InternalLink href="/components/logo" mdxType="InternalLink">{`Logo component documentation`}</InternalLink>{` for instructions on using Logo in implementation.`}</p>
    <p><strong parentName="p">{`Please do not attempt to recreate the logo!`}</strong>{` Always use the appropriate original file. The City of Helsinki logo is a registered trademark.`}</p>
    <h2 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h2" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Official City of Helsinki services must always show the City of Helsinki logo, which is placed: `}</p>
    <ul>
      <li parentName="ul">{`at the top left corner of the page, in the header component`}</li>
      <li parentName="ul">{`at the bottom of the page, in the footer component`}</li>
      <li parentName="ul">{`at the browser website title, as favicon`}</li>
    </ul>
    <p>{`In other parts of the page, the logo should be used sparingly.`}</p>
    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "sizes",
      "style": {
        "position": "relative"
      }
    }}>{`Sizes`}<a parentName="h3" {...{
        "href": "#sizes",
        "aria-label": "sizes permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The City of Helsinki logo and the accompanying language versions come in two sizes, `}<inlineCode parentName="p">{`Medium`}</inlineCode>{` and `}<inlineCode parentName="p">{`Small`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`File name`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Size (height)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/logo/fi/s/helsinki-fi-s-black.svg" alt="Small sized Helsinki logo in Finnish" viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`helsinki-fi-s-black.svg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`60px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/logo/fi/m/helsinki-fi-m-black.svg" alt="Medium sized Helsinki logo in Finnish" viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`helsinki-fi-m-black.svg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`40px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Available logo sizes]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "download",
      "style": {
        "position": "relative"
      }
    }}>{`Download`}<a parentName="h3" {...{
        "href": "#download",
        "aria-label": "download permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>You can <ExternalLink href="https://brand.hel.fi/wp-content/uploads/2018/11/helsinki_tunnus_paketti.zip" mdxType="ExternalLink">download the logo package</ExternalLink> provided by the Visual guidelines. The logos are also available in design library for Sketch and as a logo component.</p>
    <h3 {...{
      "id": "language-versions",
      "style": {
        "position": "relative"
      }
    }}>{`Language versions`}<a parentName="h3" {...{
        "href": "#language-versions",
        "aria-label": "language versions permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`There are three language versions available in HDS (you may download the logo files by clicking their images below).`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Language version`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`File name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/logo/fi/m/helsinki-fi-m-black.svg" alt="Medium sized Helsinki logo in Finnish" viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`helsinki-fi-m-black.svg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`pages in Finnish, English and other languages`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/logo/sv/m/helsinki-sv-m-black.svg" alt="Medium sized Helsinki logo in Swedish" viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`helsinki-sv-m-black.svg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`pages in Swedish and Norwegian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/foundation/visual-assets/logo/ru/m/helsinki-ru-m-black.svg" alt="Medium sized Helsinki logo in Russian" viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`helsinki-ru-m-black.svg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`pages in Russian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 2:Available logo language versions]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "colour",
      "style": {
        "position": "relative"
      }
    }}>{`Colour`}<a parentName="h3" {...{
        "href": "#colour",
        "aria-label": "colour permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The Helsinki framed logo is primarily used in black or white, depending on the background, but using it in other brand colours is also allowed.`}</p>
    <ul>
      <li parentName="ul">{`Check that the colour contrast of the logo and its background is compliant with `}<ExternalLink href="https://www.w3.org/TR/WCAG21/#contrast-minimum" mdxType="ExternalLink">{`AA Level contrast ratios`}</ExternalLink>{`.`}</li>
      <li parentName="ul">{`For more information, see the `}<ExternalLink href="https://brand.hel.fi/en/colours/" mdxType="ExternalLink">{`Visual Identity Guidelines - Colours`}</ExternalLink>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      